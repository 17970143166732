// Shards Dashboards Variables

// New Colors
$fiord-blue: #3d5170 !default;
$shuttle-gray: #5a6169 !default;
$reagent-gray: #818ea3 !default;
$mischka: #cacedb !default;
$athens-gray: #e9ecef !default;

$salmon: #ff4169 !default;
$royal-blue: #674eec !default;
$java: #1adba2 !default;

// New color maps
// Note: The $new-colors and $new-grays maps are not merged
// because not all elements (eg. buttons) should have
// grays modifiers.

// New colors map
$new-colors: () !default;
$new-colors: map-merge(
  (
    'salmon': $salmon,
    'royal-blue': $royal-blue,
    'java': $java,
  ),
  $new-colors
);

// New grays map
$new-grays: () !default;
$new-grays: map-merge(
  (
    'fiord-blue': $fiord-blue,
    'shuttle-gray': $shuttle-gray,
    'reagent-gray': $reagent-gray,
    'mischka': $mischka,
    'athens-gray': $athens-gray,
  ),
  $new-grays
);

// Accent color
// Override this color and recompile the package to quickly change
// the general accent color theme.
$accent-color: theme-color('primary') !default;

// Text color adjustments
$text-light-color: $reagent-gray !default;

// Text font weights
$text-semibold-font-weight: 400 !default;

// Fonts
$font-family-system-first: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif !default;
$font-family-roboto-mono-first: 'Roboto Mono', Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace !default;
$font-family-roboto-first: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif !default;

// Icons
$icon-color: $mischka !default;

$clear-white: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjRkZGRkZGIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gICAgPHBhdGggZD0iTTE5IDYuNDFMMTcuNTkgNSAxMiAxMC41OSA2LjQxIDUgNSA2LjQxIDEwLjU5IDEyIDUgMTcuNTkgNi40MSAxOSAxMiAxMy40MSAxNy41OSAxOSAxOSAxNy41OSAxMy40MSAxMnoiLz4gICAgPHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPjwvc3ZnPg==);
$check-white: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjRkZGRkZGIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gICAgPHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPiAgICA8cGF0aCBkPSJNOSAxNi4xN0w0LjgzIDEybC0xLjQyIDEuNDFMOSAxOSAyMSA3bC0xLjQxLTEuNDF6Ii8+PC9zdmc+);

// Shadows
$side-shadow: 0 0.125rem 9.375rem rgba($shuttle-gray, 0.1), 0 0.25rem 0.5rem rgba($shuttle-gray, 0.12),
  0 0.9375rem 1.375rem rgba($shuttle-gray, 0.1), 0 0.4375rem 2.1875rem rgba(#a5b6c9, 0.1);

// Body
$body-background: #f5f6f8 !default;
$body-font-size: 15px !default;

// Cards
$card-headings-font-weight: 500 !default;

// Card -> View Report Link
$card-view-report-font-size: 0.75rem !default;
$card-view-report-link-color: $fiord-blue !default;
$card-view-report-link-color-hover: $accent-color !default;

// Forms
$custom-control-disabled-color: #becad6 !default;

// Navbar
$navbar-brand-color: white !default;
$navbar-brand-font-weight: 300 !default;

// Navbar search
$navbar-search-padding-y: 23px !default;

// Nav
$nav-link-color: $reagent-gray !default;
$nav-link-icon-color: $nav-link-color !default;
$nav-link-icon-font-size: 1.25rem !default;
$nav-link-material-icons-font-size: 1.5625rem !default;
$nav-link-font-size: 0.8125rem !default;
$nav-link-font-weight: 400 !default;

// Main Navbar
$main-navbar-height: 90px !default;
$main-navbar-box-shadow: 0 0.125rem 0.625rem rgba(90, 97, 105, 0.12) !default;
$main-navbar-nav-link-min-width: 3.75rem !default;
$main-navbar-actions-link-border: 1px solid #e3e6ec !default;
$main-navbar-nav-link-icon-line-height: 2.5rem !default;
$main-navbar-brand-font-size: 1rem !default;

// Main Navbar -> Notifications
$notifications-badge-padding-x: 0.375rem !default;
$notifications-badge-padding-y: 0.25rem !default;
$notifications-badge-font-size: 0.5rem !default;

$notifications-dropdown-min-width: 25rem !default;
$notifications-dropdown-item-padding-top: 0.625rem !default;
$notifications-dropdown-item-padding-bottom: 0.625rem !default;
$notifications-dropdown-item-border-bottom: 1px solid $border-color !default;

$notifications-icon-background-color: $body-background !default;
$notifications-icon-width: 2.1875rem !default;
$notifications-icon-height: 2.1875rem !default;
$notifications-icon-color: $text-light-color !default;
$notifications-icon-line-height: 2.0625rem !default;
$notifications-icon-font-size: 1.0625rem !default;
$notifications-icon-box-shadow: 0 0 0 1px white, inset 0 0 3px rgba(0, 0, 0, 0.2);

$notification-content-padding: 0 0.625rem !default;
$notification-content-paragraph-font-size: 0.75rem !default;

$notification-category-font-size: 0.5625rem !default;
$notification-category-color: $reagent-gray !default;
$notification-category-letter-spacing: 0.0938rem !default;

// Main Navbar -> User Avatar
$user-avatar-max-width: 2.5rem !default;

// Header Navigation
$header-navbar-icon-font-size: 0.875rem !default;
$header-navbar-icon-margin-right: 0.125rem !default;

$header-navbar-dropdown-nav-link-padding-right: 1.25rem !default;

$header-navbar-nav-link-color: $fiord-blue !default;
$header-navbar-nav-link-border: none !default;
$header-navbar-nav-link-padding: 1.125rem 0 !default;
$header-navbar-nav-link-margin-right: 1.25rem !default;
$header-navbar-nav-link-line-height: 1 !default;
$header-navbar-nav-link-border-radius: 0 !default;
$header-navbar-nav-link-font-size: 0.8125rem !default;
$header-navbar-nav-link-background: transparent !default;
$header-navbar-nav-link-border-bottom: 1px solid transparent !default;

/* ahs: active, hover, show */
$header-navbar-nav-item-ahs-border-bottom: 1px solid $accent-color !default;
$header-navbar-nav-item-ahs-color: $accent-color !default;
$header-navbar-nav-item-ahs-icon-color: $accent-color !default;

$header-navbar-nav-link-md-font-size: 0.875rem !default;

$header-navbar-dropdown-item-md-border-radius: 5px !default;
$header-navbar-dropdown-item-md-font-size: 0.875rem !default;
$header-navbar-dropdown-item-md-padding: 0.625rem 1.375rem !default;
$header-navbar-dropdown-item-md-font-weight: 400 !default;

// Page Header
$page-title-font-size: 1.625rem !default;
$page-title-sm-font-size: 2rem !default;
$page-title-font-weight: 500 !default;
$page-title-line-height: 1 !default;
$page-title-margin: 0 !default;
$page-title-padding: 0 !default;

$page-subtitle-letter-spacing: 0.125rem !default;
$page-subtitle-color: $reagent-gray !default;
$page-subtitle-font-size: 0.625rem !default;
$page-subtitle-font-size-sm: 0.8125rem !default;
$page-subtitle-font-weight-sm: 400 !default;

// Main footer
$main-footer-height: 37px !default;
$main-footer-copyright-color: rgba(255, 255, 255, 0.75) !default;

// Main sidebar
$dropdown-icon-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMDAwMDAwIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gICAgPHBhdGggZD0iTTcuNDEgNy44NEwxMiAxMi40Mmw0LjU5LTQuNThMMTggOS4yNWwtNiA2LTYtNnoiLz4gICAgPHBhdGggZD0iTTAtLjc1aDI0djI0SDB6IiBmaWxsPSJub25lIi8+PC9zdmc+);
$dropdown-icon-width: 0.875rem;
$dropdown-icon-height: 0.5625rem;
$dropdown-icon-background-position: center center;
$dropdown-icon-transition: transform $transition-duration ease-in-out;

$main-sidebar-height: calc(100vh) !default;
$main-sidebar-zindex: 1070 !default;
$main-sidebar-background: $white !default;
$main-sidebar-will-change: transform !default;
$main-sidebar-transition: transform 200ms ease-in-out !default;

$main-sidebar-toggle-font-size: 1.25rem !default;
$main-sidebar-toggle-padding: 1.25rem !default;
$main-sidebar-toggle-border-left: 1px solid $border-color !default;

$main-sidebar-nav-wrapper-height: calc(100vh - 3.75rem - 1px - 200px) !default;
$main-sidebar-nav-border-bottom: 1px solid $border-color !default;

$main-sidebar-nav-title-text-transform: uppercase !default;
$main-sidebar-nav-title-margin: 0 !default;
$main-sidebar-nav-title-font-size: 0.625rem !default;
$main-sidebar-nav-title-letter-spacing: 0.125rem !default;
$main-sidebar-nav-title-padding: 1px 1.5625rem !default;
$main-sidebar-nav-title-font-weight: 500 !default;
$main-sidebar-nav-title-color: white !default;
$main-sidebar-nav-title-border-bottom: 1px solid $border-color !default;

$main-sidebar-nav-link-active-background-color: #fbfbfb !default;
$main-sidebar-nav-link-active-color: $accent-color !default;
$main-sidebar-nav-link-active-box-shadow: inset 0.1875rem 0 0 $accent-color !default;
$main-sidebar-nav-link-active-icon-color: $accent-color !default;

$main-sidebar-nav-link-border: 1px solid $border-color !default;
$main-sidebar-nav-link-font-weight: 400 !default;
$main-sidebar-nav-link-font-size: 0.85rem !default;
$main-sidebar-nav-link-padding-x: 1.5625rem !default;
$main-sidebar-nav-link-padding-y: 0.9375rem !default;
$main-sidebar-nav-link-color: $fiord-blue !default;
$main-sidebar-nav-link-font-family: $font-family-system-first !default;
$main-sidebar-nav-link-will-change: background-color, box-shadow, color !default;
$main-sidebar-nav-link-transition: box-shadow 200ms ease, color 200ms ease, background-color 200ms ease !default;

$main-sidebar-nav-link-icon-transition: color 200ms ease !default;
$main-sidebar-nav-link-icon-margin-right: 0.375rem !default;
$main-sidebar-nav-link-icon-color: $icon-color !default;
$main-sidebar-nav-link-icon-will-change: color !default;

$main-sidebar-dropdown-menu-box-shadow: inset 0 -0.1875rem 0.1875rem rgba($fiord-blue, 0.08) !default;

$main-sidebar-dropdown-item-background-color-active: $main-sidebar-nav-link-active-background-color !default;
$main-sidebar-dropdown-item-background-hover: none !default;
$main-sidebar-dropdown-item-padding-x: 1.75rem !default;
$main-sidebar-dropdown-item-padding-y: 0.75rem !default;
$main-sidebar-dropdown-item-border: 1px solid lighten($border-color, 5) !default;
$main-sidebar-dropdown-item-last-border: 1px solid $border-color !default;
$main-sidebar-dropdown-item-color: $fiord-blue !default;
$main-sidebar-dropdown-item-active-color: $accent-color !default;

$main-sidebar-nav-no-borders-nav-link-border-bottom: 0 !default;
$main-sidebar-nav-no-borders-dropdown-menu-box-shadow: inset 0 0 0.4375rem rgba($fiord-blue, 0.2) !default;
$main-sidebar-nav-no-borders-dropdown-item-first-border-top: $main-sidebar-dropdown-item-last-border !default;

$main-sidebar-dropdown-item-font-size: 0.8125rem !default;
$main-sidebar-dropdown-item-font-weight: 400 !default;
$main-sidebar-dropdown-item-font-weight-retina: 400 !default;

$main-sidebar-icon-nav-width: 4.75rem !default;
$main-sidebar-icon-nav-transition: transform 200ms ease-in-out, width 200ms ease-in-out !default;

// Components -> Card Post
$card-post-padding-x: 1.5625rem !default;
$card-post-padding-y: 1.5625rem !default;
$card-post-padding: $card-post-padding-y $card-post-padding-x !default;

$card-post-image-min-height: 10.3125rem !default;

$card-post-author-avatar-width: 2.8125rem !default; // 45px
$card-post-author-avatar-height: 2.8125rem !default; // 45px
$card-post-author-avatar-small-width: 2.1875rem !default; // 35px
$card-post-author-avatar-small-height: 2.1875rem !default; // 35px
$card-post-author-avatar-box-shadow: 0 0 0 0.125rem $white, 0 0.1875rem 0.4375rem rgba($blueish-grey, 0.5) !default;

// Components -> Card Post -> Variation 1
$card-post-v1-author-transform: translateY(50%) !default;
$card-post-v1-author-margin-left: 1.5625rem !default; // 25px
$card-post-v1-category-top: 0.9375rem !default; // 15px
$card-post-v1-category-right: 0.9375rem !default; // 15px
$card-post-v1-body-padding-top: 2.1875rem !default; // 35px

$card-post-aside-v1-body-padding: $card-post-padding !default;
$card-post-aside-v1-author-left: 0.9375rem !default; // 15px
$card-post-aside-v1-author-bottom: 0.9375rem !default; // 15px
$card-post-aside-v1-category-top: 0.9375rem !default; // 15px
$card-post-aside-v1-category-left: 0.9375rem !default; // 15px

// Components -> Sliders
// Core Shards UI sliders adjustments.
$slider-accent-background: $accent-color !default;
$slider-pips-font-size: 0.625rem !default; // 10px
$slider-tooltip-font-size: 0.6875rem !default; // 11px
$slider-tooltip-padding: 0.1875rem 0.5rem !default; // 3,8px
$slider-horizontal-tooltip-bottom: 1.625rem !default; // 26px
$slider-horizontal-handle-left: -0.625rem !default; // -10px
$slider-horizontal-handle-top: -0.5rem !default; // -8px
$slider-handle-width: 1.1875rem !default; // 19px
$slider-handle-height: 1.1875rem !default; // 19px

//
// Statistics Blocks and Charts Styles
//

// Chart Tooltips
$chart-tooltip-background-color: $white !default;
$chart-tooltip-border-radius: 7px !default;
$chart-tooltip-spacer-x: 10px !default;
$chart-tooltip-spacer-y: 5px !default;
$chart-tooltip-box-shadow: 0 2px 0 rgba(90, 97, 105, 0.11), 0 4px 8px rgba(90, 97, 105, 0.12), 0 10px 10px rgba(90, 97, 105, 0.06),
  0 7px 70px rgba(90, 97, 105, 0.1) !default;

// Small stats block
// Used in: Analytics, eCommerce, Blog overview page templates.
$small-stats-min-height: 8.7rem !default;

$small-stats-value-font-family: $font-family-roboto-first !default;
$small-stats-value-font-size: 1.5rem !default;
$small-stats-value-font-weight: 500 !default;
$small-stats-value-color: $fiord-blue !default;

$small-stats-label-font-size: 0.625rem !default;
$small-stats-label-color: $reagent-gray !default;
$small-stats-label-letter-spacing: 0.0625rem !default;

$small-stats-percentage-font-size: 0.75rem !default;
$small-stats-percentage-padding-left: 0.9375rem !default;

// Small Stats -- 1
$small-stats-1-data-max-width: 100% !default;
$small-stats-1-percentage-margin: 0 auto !default;
$small-stats-1-value-font-size: 2.0625rem !default; // 33px
$small-stats-1-label-font-size: 0.75rem !default; // 12px
$small-stats-1-percentage-font-size: 0.75rem !default; // 12px
$small-stats-1-chart-opacity: 0.5 !default;

// Users by device stats
// Used in: Analytics overview page template.
$ubd-stats-icon-font-size: 1.375rem !default;
$ubd-stats-icon-margin-bottom: 0.3125rem !default;

$ubd-stats-category-color: $reagent-gray !default;
$ubd-stats-category-font-size: 0.8125rem !default;
$ubd-stats-category-margin-bottom: 0.125rem !default;

$ubd-stats-value-color: $fiord-blue !default;
$ubd-stats-value-font-size: 1.0625rem !default;
$ubd-stats-value-font-weight: 500 !default;
$ubd-stats-value-line-height: 1rem !default;

// Goals overview stats
// Used in: Analytics overview page template.
$go-list-group-item-padding-y: 1rem !default;

$go-label-font-size: 0.95rem !default;
$go-label-font-weight: 500 !default;

$go-value-padding-right: 0.625rem !default;

$go-meta-color: $reagent-gray !default;
$go-meta-font-size: 0.75rem !default;
$go-meta-strong-color: $fiord-blue !default;

// Country stats
// Used in: Analytics overview page template.
$country-stats-td-padding-x: 1rem !default;
$country-stats-td-padding-y: 0.5rem !default;
$country-stats-td-font-size: 0.75rem !default;
$country-stats-td-color-dark: $fiord-blue !default;
$country-stats-td-color-light: $reagent-gray !default;
$country-stats-flag-max-width: 1.25rem !default;

// Sales by Category
// Used in: eCommerce page template
$sc-stats-font-size: 0.875rem !default;
$sc-stats-font-weight: 400 !default;
$sc-stats-responsive-font-size: 0.75rem !default;

$sc-stats-image-width: 2.8125rem !default;
$sc-stats-image-height: auto !default;
$sc-stats-image-max-width: 80px !default; // Enforce px for large displays with small pixel density
$sc-stats-image-max-width-md: 3.125rem !default;

$sc-stats-title-padding-left: 0.25rem !default;
$sc-stats-responsive-padding-left: 0.625rem !default;

$sc-stats-percentage-color: $reagent-gray !default;

$sc-stats-card-body-min-height: 12.5rem !default;
$sc-stats-card-body-responsive-min-height: 21rem !default;

$sc-row-border-bottom: 1px solid $border-color !default;
$sc-row-min-height: 3.75rem !default;

// Quick Post Form
// Used in: Blog Overview
$qp-form-display: flex !default;
$qp-form-flex-flow: column !default;
$qp-form-flex: 1 !default;

$qp-form-textarea-min-height: 100px !default;

// Blog Comments Component
// Used in: Blog Overview
$bc-avatar-img-width: 3.125rem !default; // 50px
$bc-avatar-img-height: 3.125rem !default; // 50px
$bc-avatar-img-border-radius: 0.25rem !default; // 4px

$bc-item-border-bottom: 1px solid $border-color !default;

$bc-actions-font-size: 95% !default;
$bc-actions-button-group-padding: 0.25rem 0.5625rem !default;

// Latest orders block
// Used in: eCommerce page template
$lo-font-size: 0.8125rem !default;
$lo-header-font-size: 0.75rem !default;

$lo-image-width: 3.125rem !default;
$lo-image-height: 3.125rem !default;

$lo-order-number-font-weight: 500 !default;
$lo-order-date-font-size: 0.625rem !default;
$lo-order-date-color: $reagent-gray !default;
$lo-card-body-min-height: 21rem !default;
$lo-container-min-width: 41rem !default;

$lo-row-border-bottom: 1px solid $border-color !default;
$lo-row-min-height: 3.75rem !default;

$lo-items-font-weight: 400 !default;
$lo-order-number-font-size: 1rem !default;

// File Manager Template
$fm-group-title-font-size: 0.75rem !default;
$fm-group-title-font-weight: 500 !default;
$fm-group-title-margin: $spacer / 1.5 0 !default;

// File Manager -> List
$fm-list-item-title-font-size: 1rem !default;
$fm-list-item-title-line-height: 1.25rem !default;

$fm-list-item-meta-font-size: 0.6875rem !default;
$fm-list-item-meta-color: $reagent-gray !default;
$fm-list-item-meta-line-height: 0.8125rem !default;

$fm-list-item-icon-font-size: 0.9375rem !default;
$fm-list-item-icon-text-align: center !default;
$fm-list-item-icon-color: $reagent-gray !default;
$fm-list-item-icon-width: 2.1875rem !default;
$fm-list-item-icon-height: 2.1875rem !default;
$fm-list-item-icon-line-height: 2.1875rem !default;
$fm-list-item-icon-background-color: #f5f6f8 !default;
$fm-list-item-icon-box-shadow: 0 0 0 2px $white, inset 0 0 3px rgba(0, 0, 0, 0.2) !default;

// File Manager -> Blocks/Cards
$fm-block-selected-check-bg-color: $accent-color !default;
$fm-block-selected-check-bg-image: $check-white !default;
$fm-block-selected-check-bg-size: 1.0625rem !default;
$fm-block-selected-check-width: 1.5625rem !default;
$fm-block-selected-check-height: 1.5625rem !default;
$fm-block-selected-check-right: 0.625rem !default;
$fm-block-selected-check-top: -1.875rem !default;
$fm-block-selected-transform: rotate(-90deg) !default;
$fm-block-selected-border-radius: 50% !default;
$fm-block-selected-will-change: top, right, opacity, transform !default;
$fm-block-selected-transition: top 150ms $ease-in-out-circ, right 150ms $ease-in-out-circ, opacity 150ms $ease-in-out-circ,
  transform 150ms $ease-in-out-circ !default;

$fm-block-selected-state-check-top: 0.625rem !default;
$fm-block-selected-state-check-transform: rotate(0) !default;

$fm-block-selected-outline-border-radius: $card-border-radius !default;
$fm-block-selected-outline-box-shadow: inset 0 0 0 0px rgba($accent-color, 0.2) !default;
$fm-block-selected-outline-will-change: box-shadow !default;
$fm-block-selected-outline-transition: box-shadow 250ms ease-in-out !default;

$fm-block-selected-state-outline-box-shadow: inset 0 0 3.125rem rgba($accent-color, 0.2),
  inset 0 0 0.625rem rgba($accent-color, 0.4), inset 0 0 0 0.125rem rgba($accent-color, 0.75) !default;

$fm-block-selected-dir-check-width: 1.25rem !default;
$fm-block-selected-dir-check-height: 1.25rem !default;
$fm-block-selected-dir-check-top: 50% !default;
$fm-block-selected-dir-check-transform: translateY(-50%) !default;
$fm-block-selected-dir-check-right: -1.25rem !default;

$fm-block-selected-state-dir-check-right: 10px !default;
$fm-block-selected-state-dir-background-size: 70% !default;

$fm-block-item-icon-margin-right: 0.625rem !default;
$fm-block-item-icon-font-size: 1.0625rem !default;
$fm-block-item-icon-top: 2px !default;

$fm-block-item-title-font-size: 0.875rem !default;
$fm-block-item-title-padding-right: 1.25rem !default;

$fm-block-item-size-margin: auto 0 !default;
$fm-block-item-size-font-size: 0.6875rem !default;

$fm-block-item-preview-bg: #f8f8f8 !default;

$fm-block-item-preview-img-box-shadow: 0 0.125rem 0.5625rem rgba(#000, 0.13) !default;

// User profile template
$up-user-details-img-max-height: 7.1875rem !default;
$up-user-details-img-bg: rgba($shuttle-gray, 0.3) !default;

$up-user-details-avatar-max-width: 6.25rem !default;
$up-user-details-avatar-box-shadow: $card-small-box-shadow !default;

$up-user-details-social-icon-width: 1.875rem !default;
$up-user-details-social-icon-height: 1.875rem !default;
$up-user-details-social-icon-line-height: 1.875rem !default;
$up-user-details-social-icon-background: $athens-gray !default;
$up-user-details-social-icon-color: $reagent-gray !default;

$up-user-details-data-title-color: $fiord-blue !default;
$up-user-details-data-value-color: $reagent-gray !default;

$up-user-details-stats-title-font-weight: 600 !default;
$up-user-details-stats-subtitle-font-size: 0.6875rem !default;

$up-user-team-max-width: 3.125rem !default;
$up-user-team-body-min-height: 14.0625rem !default;
$up-user-team-body-sm-min-height: 21rem !default;
$up-user-team-body-item-border-bottom: 1px solid $border-color !default;
$up-user-team-body-item-min-height: 4.6875rem !default;

$up-user-activity-item-margin-left: 1.875rem !default;
$up-user-activity-item-font-weight: 400 !default;
$up-user-activity-item-border-left: 1px solid $athens-gray !default;
$up-user-activity-item-border-bottom: 1px solid $athens-gray !default;

$up-user-activity-item-icon-width: 1.875rem !default;
$up-user-activity-item-icon-height: 1.875rem !default;
$up-user-activity-item-icon-min-width: 1.875rem !default;
$up-user-activity-item-icon-bg: lighten($athens-gray, 4) !default;
$up-user-activity-item-icon-margin-left: -0.9375rem !default;
$up-user-activity-item-icon-margin-right: 0.9375rem !default;
$up-user-activity-item-icon-box-shadow: 0 0 0 2px rgba($white, 1), inset 0 0 3px rgba($black, 0.2) !default;
$up-user-activity-item-icon-font-size: 1rem !default;
$up-user-activity-item-icon-line-height: 1.875rem !default;
$up-user-activity-item-icon-color: darken($athens-gray, 20) !default;

// Edit user profile template
$eu-bg-image-max-height: 7.1875rem !default;

$eu-bg-image-overlay-bg-color: rgba($shuttle-gray, 0.5) !default;
$eu-bg-image-overlay-will-change: background-color !default;
$eu-bg-image-overlay-transition: background-color $transition-duration ease-in-out !default;

$eu-bg-change-image-transform: translate(-50%, -50%) !default;
$eu-bg-change-image-bg-color: rgba($white, 0.06) !default;
$eu-bg-change-image-border: 1px dashed rgba(#e9ecef, 0.3) !default;
$eu-bg-change-image-padding: 0.4375rem 0.9375rem !default;
$eu-bg-change-image-color: $white !default;
$eu-bg-change-image-border-radius: $border-radius !default;
$eu-bg-change-image-box-shadow: $card-small-box-shadow !default;
$eu-bg-change-image-font-size: 0.75rem !default;
$eu-bg-change-image-will-change: opacity !default;
$eu-bg-change-image-transition: opacity $transition-duration ease-in-out, transform $transition-duration ease-in-out !default;

$eu-bg-change-image-hover-transform: translate(-50%, -50%) scale(1.05) !default;

$eu-bg-change-image-icon-font-size: 0.9375rem !default;
$eu-bg-change-image-icon-top: 0.125rem !default;

$eu-avatar-max-width: 7.5rem !default;
$eu-avatar-box-shadow: $card-small-box-shadow !default;

$eu-avatar-change-font-size: 1.875rem !default;
$eu-avatar-change-transition: all $transition-duration $ease-in-out-circ !default;
$eu-avatar-change-background: rgba(#fff, 0.95) !default;

$eu-avatar-change-icon-color: lighten($reagent-gray, 10) !default;
$eu-avatar-change-icon-line-height: 7.5rem !default;

// Authentication templates
// Used for: Login, Register, Forgot and Reset password templates.
$auth-form-min-width: 19.375rem !default;
$auth-form-max-width: 350px !default;

$auth-form-card-body-box-shadow: inset 0 4px 0 0 $accent-color !default;
$auth-form-card-body-border-radius: 0.625rem !default;

$auth-form-logo-max-width: 2.5rem !default;
$auth-form-title-font-size: 1.125rem !default;

$auth-form-social-icons-link-padding: 0 0.75rem !default;
$auth-form-social-icons-link-font-size: 1.25rem !default;
$auth-form-social-icons-link-color: $mischka !default;
$auth-form-social-icons-link-hover-color: $accent-color !default;
$auth-form-social-icons-link-transition: color $transition-duration $ease-in-out-circ !default;

$auth-form-meta-font-weight: 400 !default;
$auth-form-meta-font-color: $reagent-gray !default;
$auth-form-meta-hover-font-color: $fiord-blue !default;

// Error(s) templates
$error-tpl-height: calc(100vh - #{$main-navbar-height}) !default;

$error-tpl-content-padding: 0 0.9375rem !default; // 0,15px

$error-tpl-content-heading-color: $mischka !default;
$error-tpl-content-heading-font-weight: 700 !default;
$error-tpl-content-heading-font-size: 3.75rem !default; // 60px
$error-tpl-content-heading-margin-bottom: 1.5625rem !default; // 25px

$error-tpl-content-subheading-font-weight: 500 !default;
$error-tpl-content-subheading-font-size: 2.1875rem !default; // 35px
$error-tpl-content-subheading-margin-bottom: 0.625rem !default; // 10px

$error-tpl-content-paragraph-color: $reagent-gray !default;

//
// Plugins & Integrations
//

// Bootstrap TagsInput plugin
// Used in: User profile page template.
$bootstrap-tagsinput-border: 1px solid $border-color !default;

// DataTables
// Used in: Transaction History, File Manager (List) templates.
$dt-border-top: 1px solid $border-color !default;
$dt-font-size: 0.8125rem !default;

$dt-thead-th-background-color: #fbfbfb !default;
$dt-thead-th-border: 1px solid $border-color !default;
$dt-thead-th-font-weight: 400 !default;
$dt-thead-th-padding-y: 0.75rem !default;
$dt-thead-th-padding-x: 1.0625rem !default;

$dt-tbody-td-padding-y: 0.4375rem !default;
$dt-tbody-td-padding-x: 1.0625rem !default;
$dt-tbody-td-border: 1px solid $border-color !default;

$dt-tbody-td-empty-padding-y: 0.8125rem !default;
$dt-tbody-td-empty-padding-x: 1.0625rem !default;

$dt-wrapper-background-color: $white !default;
$dt-wrapper-border-radius: $border-radius !default;
$dt-wrapper-box-shadow: $card-small-box-shadow !default;

$dt-footer-elements-padding: 20px !default;
$dt-footer-elements-padding-sm: 14px !default;
$dt-footer-elements-background-color: #fbfbfb !default;
$dt-footer-elements-border-radius: 0 0 $border-radius $border-radius !default;

$dt-elements-color: $reagent-gray !default;
$dt-elements-font-size: 13px !default;
$dt-elements-padding-y: 15px !default;
$dt-elements-padding-x: 20px !default;

// DataTables -> Pagination
$dt-paginate-btn-padding-y: 0.4286rem !default;
$dt-paginate-btn-padding-x: 0.875rem !default;
$dt-paginate-btn-border: 1px solid #e1e5eb !default;
$dt-paginate-btn-border-radius: 0.25rem !default;
$dt-paginate-btn-color: $fiord-blue !default;
$dt-paginate-btn-bg-color: $white !default;

$dt-paginate-btn-sm-font-size: 0.625rem !default;
$dt-paginate-btn-sm-padding-x: 0.6rem !default;
$dt-paginate-btn-sm-padding-y: 0.4286rem !default;

$dt-paginate-btn-active-bg: $accent-color !default;
$dt-paginate-btn-active-color: color-yiq($accent-color) !default;
$dt-paginate-btn-active-border-color: $accent-color !default;

$dt-paginate-btn-disabled-bg: lighten($athens-gray, 3) !default;

$dt-info-sm-border-bottom: 1px solid $border-color !default;

// DataTables -> Select form control: "Show X entries"
$dt-length-select-border: 1px solid $border-color !default;
$dt-length-select-font-size: 0.625rem !default;
$dt-length-select-color: $reagent-gray !default;
$dt-length-select-height: 1.5625rem !default;
$dt-length-select-bg-color: $white !default;
$dt-length-select-border-radius: $border-radius !default;
$dt-length-select-margin-y: 0 !default;
$dt-length-select-margin-x: 0.1875rem !default;

// DataTables -> Search field
$dt-search-filter-padding: 0.625rem !default;
$dt-search-filter-font-size: 0.75rem !default;
$dt-search-filter-line-height: $input-line-height !default;
$dt-search-filter-input-color: $input-color !default;
$dt-search-filter-input-bg: $input-bg !default;
$dt-search-filter-input-border: $input-border-width solid $input-border-color !default;
$dt-search-filter-font-weight: $font-weight-normal !default;
$dt-search-filter-border-radius: 0.25rem !default;
$dt-search-filter-margin-left: 0.3125rem !default;
$dt-search-filter-input-padding: 0.4375rem 0.625rem 0.4375rem 1.75rem !default;
$dt-search-filter-input-min-width: 11.25rem !default;

// FullCalendar (Calendar Template)
$fc-toolbar-font-size: 0.875rem !default;
$fc-toolbar-font-weight: 400 !default;
$fc-toolbar-letter-spacing: 1px !default;
$fc-toolbar-color: #b9c2cd !default;

$fc-day-header-padding: 0.5rem 0 !default;
$fc-day-header-background: #fafbfc !default;

$fc-view-border-color: #ededed !default;
$fc-day-number-padding: 0.625rem 0.75rem !default;

$fc-event-background: $accent-color !default;
$fc-event-border-color: $accent-color !default;

$fc-tt-color: #fff !default;
$fc-tt-margin-left: 3px !default;

$fc-today-background: #fff3e8 !default;

$fc-button-background: #fff !default;
$fc-button-box-shadow: none !default;
$fc-button-border-color: #e1e5eb !default;
$fc-button-padding: 7px 11px !default;
$fc-button-height: auto !default;
$fc-button-font-size: 0.6875rem !default;
$fc-button-transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06) !default;
$fc-button-box-shadow-hover: 0px 0.125rem 0.625rem rgba(129, 142, 163, 0.2), 0 0.0625rem 0.125rem rgba(129, 142, 163, 0.3) !default;

$fc-day-grid-event-padding: 3px 1px !default;

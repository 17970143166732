// File Manager :: List

// @import "../../node_modules/react-table/react-table.css";
@import "./react-table";

.file-manager {
  // Filters
  &__filters {
    padding: $fml-filters-padding;
  }

  // Filters Rows
  &__filters__rows {
    font-size: $fml-filters-rows-font-size;
    font-weight: $fml-filters-rows-font-weight;
    color: $fml-filters-rows-color;

    span {
      line-height: $fml-filters-rows-span-line-height;
      margin-right: $fml-filters-rows-span-margin-right;
    }

    .custom-select {
      display: $fml-filters-rows-custom-select-display;
      width: $fml-filters-rows-custom-select-width;
      border: $fml-filters-rows-custom-select-border;
      font-size: $fml-filters-rows-custom-select-font-size;
      color: $fml-filters-rows-custom-select-color;
      height: $fml-filters-rows-custom-select-height !important;
      background-color: $fml-filters-rows-custom-select-background-color;
      border-radius: $fml-filters-rows-custom-select-border-radius;
      margin: $fml-filters-rows-custom-select-margin;
      padding: $fml-filters-rows-custom-select-padding;
      margin-top: $fml-filters-rows-custom-select-margin-top;
    }
  }

  // Search
  &__filters__search {
    input {
      display: $fml-search-input-display;
      min-width: $fml-search-input-min-width;
    }

    .input-group {
      width: $fml-search-input-min-width;
    }
  }
}

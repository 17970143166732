.custom-list {
  h1 {
    margin-bottom: 0px;
    color: white;
    font-size: 36px;
    letter-spacing: 0;
  }
  h2 {
    color: white;
    font-size: 16px;
    letter-spacing: 0;
  }
  .home-background {
    z-index: 1400;
    background-size: cover;
    min-height: 100vh;
    display: flex;
  }
  .view-side-left {
    width: 50%;
    margin-left: 0px;
    margin-right: auto;
    height: 100vh;
    background-size: cover;
    opacity: 0.54;
    backdrop-filter: blur(6px);
    @media (max-width: 992px) {
      width: 100%;
      opacity: 0.54;
    }
  }
  .view-side-left div {
    display: flex;
    flex-flow: column;
    margin-left: 120px;
    justify-content: center;
    height: 100%;
    @media (max-width: 992px) {
      justify-content: flex-start;
      margin: auto auto auto 20px;
      padding-top: 160px;
    }
  }
  .view-side-right {
    width: 50%;
    margin-right: 0px;
    margin-left: auto;
    height: 100vh;
    @media (max-width: 992px) {
      width: 100%;
    }
  }
  .elementToFadeIn1 {
    -webkit-animation: fadein 1s linear forwards;
    animation: fadein 1s linear forwards;
  }
  .custom-list-view {
    position: absolute;
    max-height: 100%;
    top: 140px;
    left: 40%;
    bottom: 0px;
  }
  .mobile-custom-list-view {
    position: absolute;
    top: 260px;
  }
  .custom-list-item {
    max-width: 680px;
    height: 215px;
    cursor: pointer;
    margin-bottom: 30px;
    border-radius: 6px;
    overflow: hidden;
    @media (max-width: 992px) {
      height: 420px;
      background: #f7f4ee;
      width: 300px;
      margin-right: 45px;
      cursor: pointer;
    }
  }
  .custom-list-item img {
    width: 340px;
    height: 215px;
    object-fit: cover;
    border-radius: 6px;
    @media (max-width: 992px) {
      width: 300px;
      height: calc(300px * 215 / 340);
      border-radius: 0;
    }
  }
  .custom-list-item .item-tab {
    position: absolute;
    width: 102px;
    height: 40px;
    opacity: 0.8;
    background: #b29368;
    border-radius: 0 0 0 6px;
    bottom: 0px;
    font-family: PingFangTC-Medium;
    font-size: 18px;
    color: #ffffff;
    text-align: center;
    font-weight: 500;
    line-height: 40px;
    @media (max-width: 992px) {
      top: calc(300px * 215 / 340 - 40px);
      border-radius: 0 0 0 0;
    }
  }
  .custom-list-item-col {
    align-self: center;
    max-height: 165px;
    margin: 0px;
  }
  .custom-list-item-col label {
    font-family: PingFangTC-Medium;
    font-size: 18px;
    color: #5e5e5e;
    text-align: justify;
    font-weight: 500;
    margin-bottom: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    @media (max-width: 992px) {
      margin-top: 15px;
    }
  }
  .custom-list-item-col p {
    font-family: PingFangTC-Regular;
    font-size: 14px;
    color: #5e5e5e;
    text-align: justify;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
  .loading-view {
    position: absolute;
    top: 0px;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    background: rgba(0, 0, 0, 0.3);
  }
  .loading-view span {
    margin-left: calc((50vw - 75px) / 2);
    margin-top: calc(50vh - 75px);
  }
  @-webkit-keyframes fadein {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fadein {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
